<template>
  <div class="smssetting">
    <Tabs type="card" :animated="false">
      <TabPane label="阿里云" name="aliyun">
        <div class="settw">
          <Form :label-width="120">
            <FormItem label="AccessKey ID" class="ivu-form-item-required">
              <Input v-model="data.aliyun.access_key" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="AccessKey Secret" class="ivu-form-item-required">
              <Input v-model="data.aliyun.access_key_secret" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="短信签名" class="ivu-form-item-required">
              <Input v-model="data.aliyun.sign_name" maxlength="300" :disabled="!editable" placeholder="必须是已添加、并通过审核的短信签名"></Input>
            </FormItem>
            <FormItem label="短信模板ID" class="ivu-form-item-required">
              <Input v-model="data.aliyun.template_code" maxlength="300" :disabled="!editable" placeholder="必须是已添加、并通过审核的短信模板"></Input>
            </FormItem>
            <FormItem label="短信余额">
              <div class="tips">不支持接口查询，需自行到 <a href="https://dysms.console.aliyun.com/dysms.htm" target="_blank">阿里云</a> 查询。</div>
            </FormItem>
            <FormItem label="使用状态">
              <i-switch true-value="aliyun" false-value="cl253" v-model="data.onuse" :disabled="!editable">
                <span slot="open">开</span>
                <span slot="close">关</span>
              </i-switch>
              <div class="tips br">阿里云和创蓝253只能开一个（打开其中一个另一个将自动关闭）</div>
            </FormItem>
            <FormItem label="">
              <Button type="info" style="width: 100px;" @click="SaveOrEdit">{{editable?'保存':'修改'}}</Button>
              <Button type="default" style="width: 100px; margin-left: 20px;" v-if="editable" @click="calcel">取消</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
      <TabPane label="创蓝253" name="cl253">
        <div class="settw">
          <Form :label-width="120">
            <FormItem label="API账号" class="ivu-form-item-required">
              <Input v-model="data.cl253.account" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="API密码" class="ivu-form-item-required">
              <Input v-model="data.cl253.password" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="短信签名" class="ivu-form-item-required">
              <Input v-model="data.cl253.sign_name" maxlength="300" :disabled="!editable" placeholder="必须是已添加、并通过审核的短信签名"></Input>
            </FormItem>
            <FormItem label="短信余额">
              <div class="tips" :class="[balance.status?'balance':'tipserr']">{{balance.status?balance.balance+'条':balance.msg}} <a href="https://zz.253.com/v5.html" target="_blank">创蓝253</a></div>
            </FormItem>
            <FormItem label="使用状态">
              <i-switch true-value="cl253" false-value="aliyun" v-model="data.onuse" :disabled="!editable">
                <span slot="open">开</span>
                <span slot="close">关</span>
              </i-switch>
              <div class="tips br">阿里云和创蓝253只能开一个（打开其中一个另一个将自动关闭）</div>
            </FormItem>
            <FormItem label="">
              <Button type="info" style="width: 100px;" @click="SaveOrEdit">{{editable?'保存':'修改'}}</Button>
              <Button type="default" style="width: 100px; margin-left: 20px;" v-if="editable" @click="calcel">取消</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
    </Tabs>
    <Spin size="large" fix v-if="page_loading"></Spin>
  </div>
</template>
<script>
export default {
  name: 'SmsSetting',
  data(){
    return {
      data:{
        'onuse':'',
        'aliyun':{
          'access_key':'',
          'access_key_secret':'',
          'sign_name':'',
          'template_code':''
        },
        'cl253':{
          'account':'',
          'password':'',
          'sign_name':''
        }
      },
      balance:{'status':0,'msg':'获取中'},
      yvalue:{},
      editable:false,
      page_loading:false,
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.page_loading=true;
      this.requestApi('/adm/smssetting.html').then(function (res){
        _this.page_loading=false;
        if(res.status==1){
          _this.data=res.data;
          _this.yvalue=JSON.stringify(res.data);
          _this.balance=res.balance;
        }
      })
    },
    /**
     * 保存选项
     */
    SaveOrEdit:function (){
      if(this.editable){//保存
        var _this=this;
        this.page_loading=true
        this.requestApi('/adm/save_smssetting.html',this.data).then(function (res){
          _this.page_loading=false;
          if(res.status==1){
            _this.editable=false;
            _this.tipSuccess(res.msg);
            _this.init();
          }else{
            _this.alertError(res.msg);
          }
        })
      }else{//修改
        this.editable=true;
      }
    },
    /**
     * 取消
     */
    calcel:function (){
      this.editable=false;
      this.data=JSON.parse(this.yvalue);
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/sysset/syssetting";
</style>